<template>
	<div id="private">
		<v-dialog name="dialog" :clickToClose="false" transition="nice-modal-fade"/>
		<!-- <div id="private-menu" :class="LOADING ? 'ui menu disabled' :  'ui menu'">
			<div class="ui fluid container">
				<div class="header item">{{app_name}}</div>
				<router-link to="home" class="item">Home</router-link>
				<router-link to="users" class="item">Users</router-link>
				<div class="right floated item" @click="logout">logout</div>
			</div>
		</div> -->

		<!-- <div id="private-content"> -->

			<!--loader-->
			<div :class="LOADING?'ui active inverted dimmer':'ui dimmer'">
				<div class="ui text loader">
					Loading...
				</div>
			</div>

			<!--route-transition-animation-->
			<transition name="fade" mode="out-in">
				<!--current-route-component-->
				<router-view :key="$route.fullPath"></router-view>
			</transition>

		<!-- </div> -->

	</div>
</template>

<script>
	import Vue from 'vue'
	import EventBus from 'vertx3-eventbus-client'
	const config = require('@/../../config.json');
	export default {
		name: "Private",
		data() {
			return {
				app_name: "Assurance"
			};
		},
		methods:{
			initEventBus(){
				const port = config.isProduction ? window.location.port : config.serverPort
				const url = window.location.protocol + '//' + window.location.hostname + ':'+ port +'/eventbus'
				Vue.prototype.$eventBus = new EventBus(url, {
					transports: [
						'xhr-polling',
						'websocket',
					],
				})
				const context = this;
				this.$eventBus.onopen = function () {
					log("eb opened");
					context.$eventBus.registerHandler(context.current_user._id, function (error, message) {
						if(error){
							console.error("eventBus error", error)
							return
						}
						context.$store.dispatch("get_notifications");
						const text = 'Nouvelle tâche reçue' + (message.body.sender ? ' de '+ message.body.sender : '')
						context.$notify({
							group: 'user-message',
							type: 'info',
							text: text,
							duration: 3000
						});
					});
				}
				this.$eventBus.onclose = function () {
					log('eb closed')
				}
			}
		},
		computed: {
			LOADING() {
				return this.$store.state.loading;
			},
			USER() {
				return this.$store.state.user;
			},
			current_user(){
				return this.$store.state.current_user;
			},
			notification_list(){
				return this.$store.state.notification_list;
			}
		},
		beforeCreate () {
			document.body.classList.remove('b-style');
		},
		mounted() {
			this.$store.dispatch("get_notifications");
			this.initEventBus();
			this.$store.state.notification_interval = setInterval(()=>{
				let notifications = this.notification_list;
				const now = new Date().getTime();
				this.notification_list.forEach((notif, index)=>{
					if(notif.date_creation > (now - 10000)){
						notifications.splice(index, 1)
					}
				});
				this.$store.commit('SET_NOTIFICATIONS', notifications);
			}, 10000);
		}
	}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
	@import "../assets/css/vue-select.css";
	.router-link-active:not(.header){
		color: var(--theme-color) !important;
		background-color: transparent !important;
	}
</style>